<template>
  <card-container>
    <div class="flex flex-col h-full p-8">
      <div class="mt-4 text-3xl font-bold text-center">Innstillinger</div>
      <div class="flex flex-col flex-grow mt-4">
        <!-- Filter -->
        <div class="flex-grow">
          <div class="px-1 text-lg font-bold border-b-2 border-gray-200">Filter</div>
          <div class="px-1 mt-2 text-sm">
            Velg hvilke egenskaper et sted må ha for å vises på kartet.
          </div>
          <div class="mt-4 text-sm">
            <toggle-label
              icon="fa-campground"
              label="Plass til telt (2933)"
              :enabled="enabled"
              @click="enabled = !enabled"
            />
            <toggle-label icon="fa-banana" label="Egnet for hengekøye (1923)" />
            <toggle-label icon="fa-person-swimming" label="Badeplass (342)" />
            <toggle-label icon="fa-droplet" label="Drikkevann (1422)" />
            <toggle-label icon="fa-fish" label="Fiskemulighet (1534)" />
            <toggle-label icon="fa-car-side" label="Kort avstand til vei (2313)" />
          </div>
          <div class="mt-5 text-sm text-right">
            Totalt <span class="font-bold font-gray-600">3342</span> steder
          </div>
        </div>
        <!-- Map -->
        <div class="flex flex-col">
          <div class="px-1 text-lg font-bold border-b-2 border-gray-200">Kart</div>
          <div class="flex gap-4 mt-2">
            <div class="flex flex-col">
              <div class="px-1 text-sm">Type</div>
              <div class="flex mt-1 text-sm">
                <button
                  class="px-4 py-2 rounded-l-full"
                  :class="[
                    mapStyle === 'normal' ? 'bg-gray-500 text-white' : 'bg-gray-200 text-gray-600',
                  ]"
                  @click="setMapStyle('normal')"
                >
                  Standard
                </button>
                <button
                  class="px-4 py-2 rounded-r-full"
                  :class="[
                    mapStyle === 'satellite'
                      ? 'bg-gray-500 text-white'
                      : 'bg-gray-200 text-gray-600',
                  ]"
                  @click="setMapStyle('satellite')"
                >
                  Satellitt
                </button>
              </div>
            </div>
            <div class="flex flex-col flex-grow">
              <div class="px-1 text-sm">Zoom</div>
              <div class="flex mt-1 text-sm">
                <button class="flex-1 py-2 text-gray-600 bg-gray-200 rounded-l-full">
                  Vis
                </button>
                <button class="flex-1 py-2 text-white bg-gray-500 rounded-r-full">Skjul</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center mt-6">
        <button
          @click="$router.push({ name: 'home', params: { transition: 'slide-bottom' } })"
          class="text-lg rounded-full w-14 h-14 ring-gray-300 ring-1"
        >
          <i class="fa-light fa-xmark fa-lg"></i>
        </button>
      </div>
    </div>
  </card-container>
</template>
<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import CardContainer from '../components/CardContainer.vue'
import ToggleLabel from '../components/ToggleLabel.vue'

export default {
  components: { CardContainer, ToggleLabel },

  setup() {
    const store = useStore()
    const enabled = ref()

    const setMapStyle = style => {
      store.commit('setMapStyle', style)
      store.commit('mapAction', { action: 'setStyle', data: style })
    }

    return {
      enabled,
      mapStyle: computed(() => store.state.mapStyle),
      setMapStyle,
    }
  },
}
</script>
