<template>
  <div class="flex w-full my-2 text-left cursor-pointer g-3">
    <div class="flex-grow">
      <div class="flex items-center gap-2">
        <i v-if="icon != null" class="text-lg text-gray-400 fal fa-fw" :class="icon"></i>
        <div>{{ label }}</div>
      </div>
      <div v-if="description != null" class="text-sm leading-snug text-gray-400">
        {{ description }}
      </div>
    </div>
    <toggle class="pointer-events-none" :enabled="enabled" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Toggle from './Toggle.vue'
export default defineComponent({
  name: 'ToggleLabel',
  components: { Toggle },
  props: {
    icon: { type: String, required: false },
    label: { type: String, required: true },
    enabled: { type: Boolean, required: true },
    description: { type: String, required: false },
  },
})
</script>
